import { adminTypes  } from './admin.types';


    const INITIAL_STATE = {

        is_getting_users: false,
        is_creating_user: false,
        is_getting_roles: false,
        is_gettting_notification: false,
        user: {},
        users: [],
        roles: [],
        notifications:[],
        notification: {},
        error: ""
    };

    const reducer = (state = INITIAL_STATE, action) => {

        switch (action.type) {
          case adminTypes.GET_USERS_STARTED:
            return {
              ...state, is_getting_users: true,
            };
          case adminTypes.GET_USERS_SUCCESS:
            return {
              ...state, is_getting_users: false, users:action.payload
            };
          case adminTypes.GET_USERS_FAILED:
            return {
              ...state, is_getting_users: false, error:action.payload.data
            };

          case adminTypes.CREATE_USER_STARTED:
            return {
              ...state, is_creating_user: true,
            };
          case adminTypes.CREATE_USER_SUCCESS:
            return {
              ...state, is_creating_user: false, user:action.payload
            };
          case adminTypes.CREATE_USER_FAILED:
            return {
              ...state, is_creating_user: false, error:action.payload.data
            };


          case adminTypes.GET_ROLES_STARTED:
            return {
              ...state, is_getting_roles: true,
            };
          case adminTypes.GET_ROLES_SUCCESS:
            return {
              ...state, is_getting_roles: false, roles:action.payload
            };
          case adminTypes.GET_ROLES_FAILED:
            return {
              ...state, is_getting_roles: false, error:action.payload.data
            };

          case adminTypes.GET_NOTIFICATIONS_STARTED:
            return {
              ...state, is_gettting_notification: true,
            };
          case adminTypes.GET_NOTIFICATIONS_SUCCESS:
            return {
              ...state, is_gettting_notification: false, notifications:action.payload
            };
          case adminTypes.GET_NOTIFICATIONS_FAILED:
            return {
              ...state, is_gettting_notification: false, error:action.payload.data
            };

          default: return state;

        }

    };

    export default reducer;