import { userTypes  } from './users.types';


    const INITIAL_STATE = {

        is_signing_in: false,
        is_verifying_otp: false,
        user: {},
        error: {},
        veri_data: {}
    };

    const reducer = (state = INITIAL_STATE, action) => {

        switch (action.type) {
          case userTypes.LOGIN_USER_STARTED:
            return {
              ...state, is_signing_in: true,
            };
          case userTypes.LOGIN_USER_SUCCESS:
            return {
              ...state, is_signing_in: false, user:action.payload
            };
          case userTypes.LOGIN_USER_FAILED:
            return {
              ...state, is_signing_in: false, error:action.payload.data
            };

          case userTypes.VERIFY_OTP_STARTED:
            return {
              ...state, is_verifying_otp: true,
            };
          case userTypes.VERIFY_OTP_SUCCESS:
            return {
              ...state, is_verifying_otp: false, veri_data:action.payload
            };
          case userTypes.VERIFY_OTP_FAILED:
            return {
              ...state, is_verifying_otp: false, error:action.payload.data
            };
            
          default: return state;

        }

    };

    export default reducer;