import { combineReducers } from 'redux';


import servicesReducer from '../includes/Services/services.reducer.js';
import usersReducer from '../includes/Users/users.reducer.js';
import adminReducer from '../includes/Admin/admin.reducer.js'
import inspectorReducer from '../includes/Inspection/inspection.reducer.js'

const rootReducer = combineReducers({

    services: servicesReducer,
    users: usersReducer,
    admin: adminReducer,
    inspection: inspectorReducer
});

export default rootReducer;