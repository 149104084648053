import React, {lazy, Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CourtOfArms from './assets/images/court_of_arms.jpg'

const MainPage = lazy(() => import('./pages/home'))
const LoginPage = lazy(() => import('./pages/admin/layouts/login'))
const AdminDashboardPage = lazy(() => import('./pages/admin/layouts/dashboard/index'));
const LicenceConfigPage = lazy(() => import('./pages/admin/layouts/configuration'));
const AdminReportPage = lazy(() => import('./pages/admin/layouts/reports'));
const AdminSettingsPage = lazy(() => import('./pages/admin/layouts/settings/'));
const InspectionPage = lazy(() => import('./pages/admin/layouts/dashboard/inspector/new-inspection'));


function App() {
  return (
    <Router>
      <Suspense fallback={
          <div className='w-screen h-screen flex flex-col justify-center items-center'>
              <div className="flex-shrink-0">
                <img
                  className="h-20 w-20 rounded-full p-2 bg-ntrBgLite"
                  src={CourtOfArms}
                  alt="Workflow"
                  loading="lazy"
                />
              </div>
              <p className='animate-pulse text-base pt-3'>LOADING</p>
          </div>
        }>
        <Switch>
          <Route exact path="/" render={props => <MainPage {...props} />} />
          <Route path="/services" render={props => <MainPage {...props} />} />
          <Route path={"/portal/dashboard"} exact={true}  render={props => <AdminDashboardPage {...props} />} />
          <Route path={"/portal/inspection"} exact={true}  render={props => <InspectionPage {...props} />} />
          <Route path={"/portal/inspection/:id"} exact={true}  render={props => <InspectionPage {...props} />} />
          <Route path={"/portal/reports"} exact={true}  render={props => <AdminReportPage {...props} />} />
          <Route path={"/portal/configurations"} exact={true}  render={props => <LicenceConfigPage {...props} />} />
          <Route path={"/portal/settings"} render={props => <AdminSettingsPage {...props} />} />
          <Route path={"/portal"} exact={true} render={props => <LoginPage {...props} />} />
          <Route path={"*"} render={props => <MainPage {...props} />} />
        </Switch>
      </Suspense>
    </Router>

  );
}

export default App;
