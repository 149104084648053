export const adminTypes = {

  GET_USERS_STARTED: "GET_USERS_STARTED",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILED: "GET_USERS_FAILED",

  CREATE_USER_STARTED: "CREATE_USER_STARTED",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILED: "CREATE_USER_FAILED",
  
  CREATE_ROLE_STARTED: "CREATE_ROLE_STARTED",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILED: "CREATE_ROLE_FAILED",

  GET_ROLES_STARTED: "GET_ROLES_STARTED",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILED: "GET_ROLES_FAILED", 

  GET_NOTIFICATIONS_STARTED: "GET_NOTIFICATIONS_STARTED",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED" 

}