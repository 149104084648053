import { inspectionTypes  } from './inspection.types';


    const INITIAL_STATE = {
        current_step: 0,
        is_loading: false,
        inspection_purposes: null,
        inspection_points: null,
        inspection_fields: null,
        inspection_sheet: null,
        vehicle_purposes: null,
        inspection_grades: null,
        draft_inspections: null,
        new_vehicle_inspection: null,
        selected: {
          related_vehicle_purpose:null,
          related_inspection_point:null,
          related_inspection_station: null,
          inspection_purpose:null,
          inspection_point: null,
          vehicle_type: null,
          inspection_station: null,
          plate_no: null,
          registered_owner: null,
          vehicle_purpose: null,
        },
        error: ""
    };

    const reducer = (state = INITIAL_STATE, action) => {

        switch (action.type) {
          case inspectionTypes.GET_VEHICLE_INSPECTION_PURPOSE_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_PURPOSE_SUCCESS:
            return {
              ...state, is_loading: false, inspection_purposes:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_PURPOSE_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_INSPECTION_POINT_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_POINT_SUCCESS:
            return {
              ...state, is_loading: false, inspection_points:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_POINT_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_INSPECTION_FIELDS_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_FIELDS_SUCCESS:
            return {
              ...state, is_loading: false, inspection_fields:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_FIELDS_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_PURPOSE_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_PURPOSE_SUCCESS:
            return {
              ...state, is_loading: false, vehicle_purposes:action.payload
            };
          case inspectionTypes.GET_VEHICLE_PURPOSE_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_INSPECTION_SHEET_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_SHEET_SUCCESS:
            return {
              ...state, is_loading: false, inspection_sheet:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_SHEET_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_INSPECTION_GRADES_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_INSPECTION_GRADES_SUCCESS:
            return {
              ...state, is_loading: false, inspection_grades:action.payload
            };
          case inspectionTypes.GET_INSPECTION_GRADES_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.UPDATE_SELECTED_STARTED:
            return {
              ...state, updating_user_input: true,
            };
          case inspectionTypes.UPDATE_SELECTED_SUCCESS:
            state["selected"][action.payload.name] = action.payload.value
            state["error"][action.payload.name] = action.payload.err
            return {
              ...state,
            };
          case inspectionTypes.UPDATE_SELECTED_FAILED:
            return {
              ...state, errors: action.payload
            };

          case inspectionTypes.CREATE_VEHICLE_INSPECTION_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.CREATE_VEHICLE_INSPECTION_SUCCESS:
            return {
              ...state, is_loading: false, new_vehicle_inspection: action.payload
            };
          case inspectionTypes.CREATE_VEHICLE_INSPECTION_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_DRAFT_INSPECTIONS_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_DRAFT_INSPECTIONS_SUCCESS:
            return {
              ...state, is_loading: false, draft_inspections:action.payload
            };
          case inspectionTypes.GET_DRAFT_INSPECTIONS_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.COMPLETE_VEHICLE_INSPECTION_ITEMS_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.COMPLETE_VEHICLE_INSPECTION_ITEMS_SUCCESS:
            return {
              ...state, is_loading: false
            };
          case inspectionTypes.COMPLETE_VEHICLE_INSPECTION_ITEMS_FAILED:
            return {
              ...state, is_loading: false, error:action.payload
            };

          case inspectionTypes.SAVE_VEHICLE_INSPECTION_DRAFT_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.SAVE_VEHICLE_INSPECTION_DRAFT_SUCCESS:
            return {
              ...state, is_loading: false
            };
          case inspectionTypes.SAVE_VEHICLE_INSPECTION_DRAFT_FAILED:
            return {
              ...state, is_loading: false, error:action.payload
            };

          default: return state;

        }

    };

    export default reducer;